import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Select from 'components/Inputs/Select/Select';

import { DiagnosticVersionStatus, UserStatus } from 'types/enums';

import styles from './Status.module.scss';

type PropsInline = {
  inline: boolean;
  value: string;
  required?: never;
  onChange?: never;
  error?: never;
  errorMessage?: never;
  isActive?: never;
  isRow?: never;
};

type PropsRow = {
  required: boolean;
  value: string;
  onChange: (value: string) => void;
  error: any;
  errorMessage: string;
  inline?: never;
  isActive?: never;
  isRow?: never;
};

type PropsSelect = {
  isActive: boolean;
  isRow: boolean;
  inline?: never;
  required?: never;
  value?: never;
  onChange?: never;
  error?: never;
  errorMessage?: never;
};

type Props = PropsRow | PropsSelect | PropsInline;

const Status = ({ isActive, isRow, inline, required, value, onChange, error, errorMessage }: Props) => {
  const { t } = useTranslation();

  const userStatusData = [
    {
      value: UserStatus.Active,
      label: (
        <div className={styles.Active}>
          <div className={styles.Status} />
          {t('general.active')}
        </div>
      ),
    },
    {
      value: UserStatus.Disabled,
      label: (
        <div className={styles.Disabled}>
          <div className={styles.Status} />
          {t('general.disabled')}
        </div>
      ),
    },
  ];

  if (inline) {
    const DIAGNOSTIC_VERSION_STATUSES = {
      [DiagnosticVersionStatus.Sent]: { title: t('general.sent'), color: styles.Sent },
      [DiagnosticVersionStatus.Opened]: { title: t('general.opened'), color: styles.Opened },
      [DiagnosticVersionStatus.Completed]: { title: t('general.completed'), color: styles.Completed },
      [DiagnosticVersionStatus.Scheduled]: { title: t('general.scheduled'), color: styles.Opened },
      [DiagnosticVersionStatus.ScheduledSendingError]: {
        title: t('general.error'),
        color: styles.Disabled,
      },
    };

    const status = DIAGNOSTIC_VERSION_STATUSES[value as DiagnosticVersionStatus];

    return (
      <div className={classNames(styles.StatusInline, status.color)}>
        <div className={styles.Status} />
        {status.title}
      </div>
    );
  }

  return isRow ? (
    <div className={styles.StatusRow}>
      <span>{t('inputs.status')}</span>
      <div className={styles.StatusField}>
        <div className={classNames(isActive ? styles.Active : styles.Disabled)}>
          <div className={styles.Status} />
          {isActive ? t('general.active') : t('general.disabled')}
        </div>
      </div>
    </div>
  ) : (
    <Select
      required={required}
      value={value}
      onSelect={onChange!}
      label={t('inputs.status')}
      data={userStatusData}
      error={error}
      error_message={errorMessage}
    />
  );
};

export default Status;
