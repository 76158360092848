import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { Pagination, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';
import EditUnitModal from 'components/Modals/EditUnitModal/EditUnitModal';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';

import AssignedToCell from '../Table/components/AssignedToCell/AssignedToCell';
import AccordionCustom from './components/AccordionCustom';
import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import useTableControl from 'hooks/useTableControl';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDate } from 'helpers/date';
import { formatDiagnosticAssignmentCompletionRate } from 'helpers/diagnostic';
import { getCurrentPageItems } from 'helpers/pagination';

import { MOBILE_BREAKPOINT } from 'constants/';

import { IAssessment, IAssessmentListItem, ITableProps } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as ArrowNarrowRight } from 'assets/images/arrow-narrow-right.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import Table from '../Table/Table';

import styles from './AssessmentListItem.module.scss';

type Props = {
  unitTitle?: string;
  unitId?: string;
  assessments: IAssessment[];
  tableProps: ITableProps;
  total?: number;
};

type DataTable = {
  id: string;
  diagnosticAssignmentId: string;
  diagnosticId: string;
  fieldId: number;
  name: string;
  description: string;
  creator: string;
  assignedTo: string[];
  attemptDate: Date;
  isAssignedToCurrentUser: boolean;
};

const AssessmentListItem = ({ unitTitle, unitId, tableProps, assessments, total }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assessment, unit, other_users_dashboard_comparison } = useAppSelector(
    (state) => state.permissions.permissions,
  );
  const [openEditUnitModal, toggleEditUnitModal] = useState(false);
  const { page, onPageChange } = useTableControl('title');
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const pageTableProps = {
    ...tableProps,
    page,
    onPageChange,
  };
  const canOpenDiagnosticAssignmentDetails = checkPermission(other_users_dashboard_comparison, [
    PermissionsAction.READ,
  ]);

  const onRowClick = ({ row: { diagnosticAssignmentId } }: GridRowParams) => {
    navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(diagnosticAssignmentId));
  };

  const handleCloseEditUnitModal = () => toggleEditUnitModal(false);

  const handleTakeAssessment = useCallback(
    (diagnosticId: string, diagnosticAssignmentId: string) => {
      navigate(ROUTES.DIAGNOSTIC_TAKE(diagnosticId, diagnosticAssignmentId));
    },
    [navigate],
  );

  const getActions = useCallback(
    (row: { diagnosticAssignmentId: string; isAssignedToCurrentUser: boolean; diagnosticId: string }) => {
      const actions: ReactNode[] = [];

      if (
        isMobile &&
        (checkPermission(assessment, [PermissionsAction.READ]) ||
          checkPermission(assessment, [PermissionsAction.CREATE]))
      ) {
        actions.push(
          <ButtonStyled
            key="open"
            fill={ButtonFill.Transparent}
            size={ButtonSize.Link}
            onClick={() => navigate(ROUTES.DIAGNOSTIC_DETAILS(row.diagnosticAssignmentId))}
          >
            {t('general.open')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(assessment, [PermissionsAction.READ]) && row.isAssignedToCurrentUser) {
        actions.push(
          <ButtonStyled
            key="take"
            onClick={(event) => {
              event.stopPropagation();
              handleTakeAssessment(row.diagnosticId.toString(), row.diagnosticAssignmentId.toString());
            }}
            fill={ButtonFill.Contained}
            size={ButtonSize.Small}
          >
            {t('general.take_diagnostic')}
          </ButtonStyled>,
        );
      } else if (canOpenDiagnosticAssignmentDetails) {
        actions.push(
          <ButtonStyled
            key="details"
            onClick={(e) => {
              e.stopPropagation();
              navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(row.diagnosticAssignmentId));
            }}
            fill={ButtonFill.Outlined}
            size={ButtonSize.Icon}
            icon={<ArrowNarrowRight />}
          />,
        );
      }

      return actions;
    },
    [assessment, canOpenDiagnosticAssignmentDetails, handleTakeAssessment, isMobile, navigate, t],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 30, align: 'center', headerAlign: 'center' },
      {
        field: 'name',
        headerName: t('assessment.diagnostic_versions_table.internal_name'),
        flex: 1,
        sortable: true,
        renderCell: (params) => (
          <Tooltip title={params.value} placement="bottom-start">
            <span
              style={{
                textDecoration: 'underline',
                color: 'inherit',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'creator',
        headerName: t('assessment.diagnostic_versions_table.creator'),
        sortable: true,
        flex: 1,
        valueFormatter: (data) => data.value || ' - ',
      },
      {
        field: 'completionRate',
        headerName: t('assessment.diagnostic_versions_table.completion_rate'),
        sortable: true,
        width: 110,
        sortComparator: (v1, v2) => v1.completion_rate - v2.completion_rate,
        valueFormatter: (data) => formatDiagnosticAssignmentCompletionRate(data.value) || ' - ',
      },
      {
        field: 'createdAt',
        headerName: t('assessment.diagnostic_versions_table.created_date'),
        sortable: true,
        width: 91,
        valueFormatter: (data) => formatDate(data.value) || ' - ',
      },
      {
        field: 'assignedTo',
        headerName: t('assessment.table.assigned_to'),
        sortable: false,
        valueFormatter: (data) => data.value[0] || t('general.not_assigned'),
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
        width: 125,
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        width: 175,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, DataTable>) =>
          getActions({
            diagnosticAssignmentId: params.row.diagnosticAssignmentId,
            diagnosticId: params.row.diagnosticId,
            isAssignedToCurrentUser: params.row.isAssignedToCurrentUser,
          }),
      },
    ],
    [getActions, t],
  );

  // TODO: Update for mobile version
  const dataTable: IAssessmentListItem[] = [];
  const mobileCardsData: MobileCardsData = [];

  return (
    <Fragment>
      {unitTitle && (
        <EditUnitModal
          enableDelete
          open={openEditUnitModal}
          handleClose={handleCloseEditUnitModal}
          unitName={unitTitle}
          unitId={unitId || ''}
        />
      )}

      {Boolean(tableProps.search) ? (
        <>
          {isMobile ? (
            <div className={styles.MobileContainer}>
              {tableProps.isLoading || tableProps.isFetching ? (
                <Loader flexCenter size="md" />
              ) : (
                <>
                  {mobileCardsData?.length && total ? (
                    <>
                      <MobileCards data={mobileCardsData} />
                      <div className={styles.PaginationContainer}>
                        <Pagination
                          color="primary"
                          shape="rounded"
                          page={tableProps.page}
                          count={Math.ceil(total / tableProps.size)}
                          onChange={(_, value) => tableProps.onPageChange(value)}
                        />
                      </div>
                    </>
                  ) : (
                    <NothingFoundText text={t('general.no_items')} />
                  )}
                </>
              )}
            </div>
          ) : (
            <Table assessments={dataTable} columns={columns} total={total} {...tableProps} />
          )}
        </>
      ) : (
        <AccordionCustom
          accordionContent={
            isMobile ? (
              <>
                <MobileCards data={getCurrentPageItems(mobileCardsData, page, pageTableProps.size)} />
                {mobileCardsData.length ? (
                  <div className={styles.PaginationContainer}>
                    <Pagination
                      color="primary"
                      shape="rounded"
                      page={page}
                      count={Math.ceil(dataTable.length / pageTableProps.size)}
                      onChange={(_, value) => onPageChange(value)}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              assessments.map((itemDiagnostic) => {
                const assessments = itemDiagnostic?.versions?.map((item, index) => {
                  const {
                    id,
                    created_at,
                    version_name: title,
                    users,
                    groups,
                    is_assigned_to_current_user,
                    creator_full_name,
                    completion_rate,
                    send_date,
                    updated_at,
                  } = item;

                  const assignedToUsers = users.map((item) => item.first_name + ' ' + item.last_name);
                  const assignedToGroups = groups.map((item) => item.name);
                  const assignedTo = [...assignedToUsers, ...assignedToGroups];

                  return {
                    id: String(id + index),
                    diagnosticAssignmentId: id,
                    diagnosticId: itemDiagnostic.id,
                    name: title,
                    creator: creator_full_name,
                    completionRate: completion_rate,
                    sendDate: send_date,
                    assignedTo: assignedTo,
                    attemptDate: new Date(created_at),
                    updatedAt: new Date(updated_at),
                    createdAt: new Date(created_at),
                    isAssignedToCurrentUser: is_assigned_to_current_user,
                  };
                });

                return (
                  <AccordionCustom
                    key={itemDiagnostic.id}
                    subAccordion
                    accordionContent={
                      isMobile ? (
                        <>
                          <MobileCards data={getCurrentPageItems(mobileCardsData, page, pageTableProps.size)} />
                          {mobileCardsData.length ? (
                            <div className={styles.PaginationContainer}>
                              <Pagination
                                color="primary"
                                shape="rounded"
                                page={page}
                                count={Math.ceil(dataTable.length / pageTableProps.size)}
                                onChange={(_, value) => onPageChange(value)}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <Table
                          newDesign
                          assessments={assessments}
                          columns={columns}
                          {...pageTableProps}
                          onRowClick={canOpenDiagnosticAssignmentDetails ? onRowClick : undefined}
                          emptyResultText={t('assessment.table.assignments_empty_result')}
                        />
                      )
                    }
                    accordionSummaryChildren={
                      <Fragment>
                        <span className={styles.AccordionSummaryChildrenTitleContainer}>
                          <span className={styles.AccordionSummaryChildrenTitle}>{itemDiagnostic.title}</span>
                          <span className={styles.AccordionSummaryChildrenCount}>
                            ({itemDiagnostic.versions.length})
                          </span>
                        </span>
                        {checkPermission(unit, [PermissionsAction.UPDATE]) && (
                          <ButtonStyled
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(ROUTES.DIAGNOSTIC_DETAILS(itemDiagnostic.id));
                            }}
                            fill={ButtonFill.Transparent}
                            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
                          >
                            {t('assessment.see_details')}
                          </ButtonStyled>
                        )}
                      </Fragment>
                    }
                  />
                );
              })
            )
          }
          accordionSummaryChildren={
            <Fragment>
              <span className={styles.AccordionSummaryChildrenTitleContainer}>
                <span className={styles.AccordionSummaryChildrenTitle}>{unitTitle}</span>
                <span className={styles.AccordionSummaryChildrenCount}>({assessments.length})</span>
              </span>
              {checkPermission(unit, [PermissionsAction.UPDATE]) && (
                <ButtonStyled
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEditUnitModal(true);
                  }}
                  fill={ButtonFill.Outlined}
                  size={isMobile ? ButtonSize.Link : ButtonSize.Small}
                  icon={<Edit />}
                >
                  {t('assessment.edit_unit')}
                </ButtonStyled>
              )}
            </Fragment>
          }
        />
      )}
    </Fragment>
  );
};

export default AssessmentListItem;
