import * as React from 'react';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as DotsVerticalIcon } from 'assets/images/dots-vertical.svg';

import styles from './ThreeDotsMenu.module.scss';

type Props = {
  options: {
    title: string;
    icon: React.ReactNode;
    color?: string;
    action?: () => void;
  }[];
};

const ThreeDotsMenu = ({ options }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-paper': {
            borderRadius: '8px',
            border: '1px solid #D1EBEC',
            boxShadow: 'none',
          },
          '.MuiMenu-list': {
            padding: '4px',
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.title} onClick={option.action || handleClose}>
            <Box display="flex" alignItems="center" gap={'8px'}>
              {option.icon}
              <span className={styles.OptionTitle} style={{ color: option.color || 'var(--matte-black)' }}>
                {option.title}
              </span>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ThreeDotsMenu;
