import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from 'rsuite';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';
import Select from 'components/Inputs/Select/Select';
import TextInput from 'components/Inputs/Text/Text';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import ManageUserTags from 'components/SecondaryGroupsTags/SecondaryGroupsTags';
import Status from 'components/Status/Status';
import Title from 'components/Title/Title';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useToggle } from 'hooks/useToggle';

import { useSendActivationEmailMutation } from 'store/api/authApi/authApi';
import { useGetGroupHierarchyQuery } from 'store/api/groupsApi/groupsApi';
import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';
import {
  useCreateUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useValidateUserFromCsvMutation,
} from 'store/api/usersApi/usersApi';
import { clearUsersCsv, editCSVUser } from 'store/features/usersSlice';

import ROUTES from 'router/routes';

import { UserStatus } from 'types/enums';
import { findNestedObj } from 'helpers/findNestedObj';
import { generateKey } from 'helpers/generateKey';
import { handleNoPermissionError } from 'helpers/handleNoPermissionError';
import { getErrorEntity } from 'helpers/i18n';
import { mapToSelectGroupHierarchy } from 'helpers/mappers/mapToSelectGroupHierarchy';
import { addUserResolver } from 'helpers/validations';

import { ButtonFill, ButtonSize, Size } from 'types/enums';
import { IServerErrorWithTranslation } from 'types/errorTypes';
import { Tag, Tags } from 'types/userTypes';
import { ErrorName, ExtendedParsedUser, ParsedUserError } from 'types/userTypes';

import styles from './UserAdd.module.scss';

type Props = {
  edit?: boolean;
};

type FormValues = {
  organization: string;
  subgroup: string;
  email: string;
  first_name: string;
  last_name: string;
  group_ids: string[];
  role: string;
  photo?: string | null;
  phone_number?: string | null;
  role_name: string;
  is_active?: boolean;
  status: UserStatus | '';
  itemId: number;
  fieldId: number;
};

const UserAdd = ({ edit }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCSV = useLocation().state?.isCSV || false;
  const manageGroupId = useLocation().state?.manageGroupId || false;
  const goBackOnCancel = useLocation().state?.goBackOnCancel || false;

  const shouldClearUsersCSV = useRef(true);
  const [openSelect, toggleSelect] = useToggle(false);
  const [openDelete, toggleDelete] = useToggle(false);
  const [openView, toggleView] = useToggle(false);
  const [tag, selectTag] = useState<Tags | null>(null);

  const [secondaryGroups, changeSecondaryGroups] = useState<Tag[]>([]);

  const userFromCsv = useAppSelector((state) => state.users.userToEdit);
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);

  const [sendActivationEmail] = useSendActivationEmailMutation();

  const { register, handleSubmit, control, reset, getFieldState, formState, setValue, setError, trigger, watch } =
    useForm<FormValues>({
      mode: 'onSubmit',
      resolver: yupResolver(addUserResolver(t)),
    });

  const {
    isLoading: isLoadingGroups,
    isSuccess: isSuccessGroups,
    data: groupsData,
  } = useGetGroupHierarchyQuery({ search: '', orderBy: 'name', selectedGroup });

  const {
    data: userData,
    isLoading: isLoadingUser,
    isSuccess: isSuccessUser,
    isError,
  } = useGetUserByIdQuery(id ?? skipToken, { refetchOnMountOrArgChange: true, skip: isCSV });

  const {
    data: roles,
    isSuccess: isSuccessRoles,
    isFetching: isFetchingRoles,
  } = useGetRolesQuery(
    { groupId: watch('subgroup') || userData?.group_accesses[0]?.unit_id },
    { skip: !(id ? userData : watch('subgroup')) },
  );

  const [createUser, { isLoading: creatingUser }] = useCreateUserMutation();
  const [updateUser, { isLoading: updatingUser }] = useUpdateUserMutation();
  const [validateUser] = useValidateUserFromCsvMutation();

  const mappedGroups = groupsData?.items.map((el) => mapToSelectGroupHierarchy(el)) || [];

  useEffect(() => {
    if (isError && !isCSV) {
      navigate(ROUTES.USER_LIST);
    }
  }, [isCSV, isError, navigate]);

  const handleSendInviteEmail = () => {
    if (id) {
      sendActivationEmail({ userId: id })
        .unwrap()
        .then(() => toast.success(t('dashboard.email_sent_successfully')))
        .catch(() => {
          toast.error(t('dashboard.email_is_not_sent'));
        });
    }
  };

  const validateCsvErrors = useCallback(
    (errors: ParsedUserError[]) => {
      errors.forEach((error) => {
        if (error.error && error.name) {
          const message = t(error.error, {
            name: error.name[0].toUpperCase() + error.name.slice(1).toLowerCase().replace('_', ' '),
            value: error.items?.[0],
          });

          switch (error.name) {
            case ErrorName.Email:
              setError('email', {
                message,
              });
              break;
            case ErrorName.Group:
              setError('subgroup', {
                message,
              });
              break;
            case ErrorName.Role:
              setError('role', {
                message,
              });
              break;
            case ErrorName.FirstName:
              trigger('first_name');
              break;
            case ErrorName.LastName:
              trigger('last_name');
              break;
          }
        }
      });
    },
    // No need to recreate the function when "t" changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setError, trigger],
  );

  useEffect(() => {
    if (isCSV && userFromCsv) {
      if (userFromCsv.accesses.length > 1) {
        const groupsList = userFromCsv.accesses.slice(1).reduce((acc: Tag[], item) => {
          const findGroup = findNestedObj({ ...groupsData?.items }, 'id', item.group_id);
          const findRole = roles?.items.find((el) => el.id === (item?.role_id ?? userFromCsv.accesses[0].role_id));
          acc.push({
            group: { value: findGroup?.id || '', label: findGroup?.name || '' },
            role: { value: findRole?.id || '', label: findRole?.name || '' },
          });

          return acc;
        }, []);

        changeSecondaryGroups(groupsList);
      }

      reset({
        ...userFromCsv,
        status: userFromCsv.is_active ? UserStatus.Active : UserStatus.Disabled,
        role: userFromCsv?.accesses?.[0]?.role_id ?? '',
        subgroup: userFromCsv?.accesses?.[0]?.group_id ?? '',
      });

      validateCsvErrors(userFromCsv.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsData?.items, isCSV, reset, setValue, userFromCsv, validateCsvErrors]);

  useEffect(() => {
    if (isSuccessUser && isSuccessGroups && isSuccessRoles && !watch('subgroup')) {
      prefillData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isSuccessRoles, isSuccessUser, isSuccessGroups, userData]);

  useEffect(() => {
    return () => {
      if (isCSV && shouldClearUsersCSV.current) {
        dispatch(clearUsersCsv());
      }
    };
  }, [dispatch, isCSV]);

  const prefillData = () => {
    if (userData) {
      const firstRole = roles?.items.find((el) => el.name === userData.group_accesses[0].role);
      const status = userData.is_active ? UserStatus.Active : UserStatus.Disabled;

      if (userData.group_accesses.length > 1) {
        const groupsList = [];

        for (let i = 1; i < userData.group_accesses.length; i++) {
          const access = userData.group_accesses[i];
          const secondGroup = {
            group: { value: access.unit_id, label: access.unit },
            role: { value: access.role_id, label: access.role },
          };

          groupsList.push(secondGroup);
        }

        changeSecondaryGroups(groupsList);
      }

      reset({ ...userData, status, subgroup: userData.group_accesses[0].unit_id, role: firstRole?.id });
    }
  };

  // Submitting

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { status, ...formData } = data;

    if (isCSV) {
      shouldClearUsersCSV.current = false;

      const editedCSV = {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        accesses: [
          {
            group_id: formData.subgroup,
            group_name: findNestedObj({ ...groupsData?.items }, 'id', formData.subgroup)?.name || '',
            role_id: formData.role,
            role_name: roles?.items.find((item) => item.id === formData.role)?.name || '',
          },
          ...secondaryGroups.map((el) => ({
            group_id: el.group.value,
            group_name: el.group.label,
            role_id: el.role.value,
            role_name: el.role.label,
          })),
        ],
        is_active: status === UserStatus.Active,
        itemId: formData.itemId,
        fieldId: formData.fieldId,
      } as ExtendedParsedUser;

      const payload = {
        email: editedCSV.email,
        first_name: editedCSV.first_name,
        last_name: editedCSV.last_name,
        accesses: editedCSV.accesses,
        is_active: editedCSV.is_active,
      };

      validateUser(payload)
        .unwrap()
        .then((response) => {
          if (response.errors.length) {
            validateCsvErrors(response.errors);
            return;
          }
          dispatch(editCSVUser({ ...editedCSV, ...response, id: userFromCsv?.id || generateKey() }));

          manageGroupId
            ? navigate(ROUTES.GROUP_MANAGE(manageGroupId), { state: { edit: true } })
            : navigate(ROUTES.USER_ADD_CSV, { state: { access: true } });
        })
        .catch((error) => handleNoPermissionError(error.status));

      return;
    }

    const new_user = {
      ...formData,
      is_active: status === UserStatus.Active,
      accesses: [
        { group_id: formData.subgroup, role_id: formData.role },
        ...secondaryGroups.map((el) => ({
          group_id: el.group.value,
          role_id: el.role.value,
        })),
      ],
      group_accesses: undefined,
    };

    delete new_user?.group_accesses;

    const setUserError = (error: IServerErrorWithTranslation, field: keyof FormValues) => {
      const { status } = error;
      const { detail } = error.data;

      if (status === 400) {
        const { entity, value } = getErrorEntity(error);

        setError(field, {
          type: 'server',
          message: t(detail.error, {
            entity,
            value,
            field: t(`server.field.${field}`).toLowerCase(),
            ...detail.items,
          }),
        });
      }
    };

    id
      ? updateUser({
          id,
          user: {
            email: new_user.email,
            first_name: new_user.first_name,
            last_name: new_user.last_name,
            photo: new_user.photo,
            phone_number: new_user.phone_number,
            is_active: new_user.is_active,
            accesses: new_user.accesses,
          },
        })
          .unwrap()
          .then(() => navigate(ROUTES.USER_LIST))
          .catch((error) => {
            handleNoPermissionError(error.status);
            setUserError(error, 'email');
          })
      : createUser({
          email: new_user.email,
          first_name: new_user.first_name,
          last_name: new_user.last_name,
          accesses: new_user.accesses,
          is_active: new_user.is_active,
        })
          .unwrap()
          .then(() => navigate(ROUTES.USER_LIST))
          .catch((error) => {
            handleNoPermissionError(error.status);
            setUserError(error, 'email');
          });
  };

  const clearForm = () =>
    reset((formValues) => ({
      ...formValues,
      status: '',
      email: '',
      first_name: '',
      last_name: '',
    }));

  const handleCancel = () => {
    shouldClearUsersCSV.current = false;

    if (isCSV && !manageGroupId) {
      return navigate(ROUTES.USER_ADD_CSV, { state: { access: true } });
    }

    if (goBackOnCancel) {
      return navigate(-1);
    }

    navigate(ROUTES.USER_LIST);
  };

  useDocumentTitle(id ? [t('page_titles.user_edit')] : [t('page_titles.user_add')]);

  return (
    <div className={styles.PageContainer}>
      <Breadcrumb
        items={[
          { name: t('users.user_list'), href: ROUTES.USER_LIST },
          isCSV || edit
            ? {
                name: t('users.edit_user'),
                href: ROUTES.USER_EDIT(id),
                active: true,
              }
            : {
                name: t('users.add_single_user'),
                href: ROUTES.USER_ADD,
                active: true,
              },
        ]}
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          return handleSubmit(onSubmit)().then(() => userFromCsv?.errors && validateCsvErrors(userFromCsv.errors));
        }}
        className={styles.UserForm}
      >
        {(isLoadingUser || isLoadingGroups || (id && isFetchingRoles && !watch('subgroup'))) && (
          <Loader backdrop size="lg" />
        )}

        <ShadowBlock>
          <Title
            title={edit || isCSV ? t('users.edit_user') : t('users.add_single_user')}
            description={edit || isCSV ? t('users.edit_user_description') : t('users.add_user_description')}
          />

          <Controller
            control={control}
            name="subgroup"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                cascade
                value={value}
                onSelect={onChange}
                data={mappedGroups}
                isLoading={isLoadingGroups}
                label={t('inputs.sub_group')}
                placeholder={t('inputs.sub_group_placeholder')}
                error={getFieldState('subgroup', formState)}
                error_message={t(formState?.errors.subgroup?.message || '')}
              />
            )}
          />

          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={value}
                onSelect={onChange}
                data={
                  roles?.items.map((el) => ({
                    value: el.id,
                    label: el.name,
                  })) || []
                }
                isLoading={isFetchingRoles}
                label={t('inputs.role')}
                placeholder={t('inputs.role_placeholder')}
                error={getFieldState('role', formState)}
                error_message={t(formState?.errors.role?.message || '')}
              />
            )}
          />

          <ManageUserTags
            openSelect={openSelect}
            openView={openView}
            openDelete={openDelete}
            toggleSelect={toggleSelect}
            toggleView={toggleView}
            toggleDelete={toggleDelete}
            secondaryGroups={secondaryGroups}
            changeSecondaryGroups={changeSecondaryGroups}
            groups={mappedGroups}
            tag={tag}
            selectTag={selectTag}
          />
        </ShadowBlock>

        <ShadowBlock>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Status
                required
                value={value}
                onChange={onChange}
                error={getFieldState('status', formState)}
                errorMessage={t(formState?.errors.status?.message || '')}
              />
            )}
          />

          <TextInput
            required
            label={t('inputs.first_name')}
            placeholder={t('inputs.first_name_placeholder')}
            register={register('first_name')}
            error={getFieldState('first_name', formState)}
            error_message={t(formState?.errors.first_name?.message || '')}
          />

          <TextInput
            required
            label={t('inputs.last_name')}
            placeholder={t('inputs.last_name_placeholder')}
            register={register('last_name')}
            error={getFieldState('last_name', formState)}
            error_message={t(formState?.errors.last_name?.message || '')}
          />

          <TextInput
            required
            label={t('inputs.email')}
            placeholder={t('inputs.email_placeholder')}
            register={register('email')}
            error={getFieldState('email', formState)}
            error_message={t(formState?.errors.email?.message || '')}
          />

          <div className={styles.ClearAllButtonContainer}>
            <DeleteButton title={t('general.clear_all')} clear={clearForm} size={Size.Sm} />
            {id && (
              <ButtonStyled fill={ButtonFill.Transparent} onClick={handleSendInviteEmail} size={ButtonSize.Link}>
                {t('dashboard.resend_invite')}
              </ButtonStyled>
            )}
          </div>

          <div className={styles.ControlsContainer}>
            <ButtonStyled fill={ButtonFill.Outlined} onClick={handleCancel}>
              {t('general.cancel')}
            </ButtonStyled>

            <ButtonStyled fill={ButtonFill.Contained} clickType="submit" loading={creatingUser || updatingUser}>
              {id || isCSV ? t('users.update_user') : t('users.add_user')}
            </ButtonStyled>
          </div>
        </ShadowBlock>
      </form>
    </div>
  );
};

export default UserAdd;
